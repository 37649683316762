<template>
  <!--<div class="main-frame">
    <TopNavigation title="Azad Barkho" :items="[{name:'Home',isActive:true},{name:'About',isActive:false}]" />
  </div>-->
  <ThreeBackground/>
  <MaintenancePage title="Es wird gebaut" content="Willkommen auf der personlichen Seite von Azad Barkho."/>
  <!--<TextGeometry text="TEST test set"/>-->
</template>

<script>
import { defineComponent } from 'vue';
//import TopNavigation from './components/TopNavigation.vue';
//import TextGeometry from './components/customGeometries/TextGeometry.vue';
import ThreeBackground from './components/ThreeBackground.vue';
import MaintenancePage from './components/MaintenancePage.vue'

export default defineComponent({
  name: 'App',
  components: {
    //TopNavigation,
    ThreeBackground,
    MaintenancePage
  }
});
</script>

<style>
/* body{
  margin: 0;
}
#app{
  width: 100%;
  height: 99.6vh;
} */
</style>
