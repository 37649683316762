<template>
  <div class="background-component">
    <Renderer @pointerMove="onPointerMove" ref="renderer" @pointer="(pointer)=>console.log(pointer)" resize="window" :orbit-ctrl="{  enableDamping: true, dampingFactor: 0.05, autoRotate : false, enableZoom:true }" shadow>
      <Camera :position="this.cameraPosition" />
      <Scene>
        <PointLight color="#75C804" :intensity="0.2" :position="lightPosition" :cast-shadow="false" :shadow-map-size="{ width: 1024, height: 1024 }" />
        <SpotLight color="#E7F928" :intensity="0.2" :position="{y: -2000 }" :cast-shadow="false" :shadow-map-size="{ width: 1024, height: 1024 }" />
        <!-- Alle elemente vom Bits-Array durchlaufen -->
        <span v-for="(startPoint,key1) in bitArray" :key="key1">
          <!-- 2. Schleife um Anzahl von Bits-Array MINUS der bereits durchgelaufenen Stellen in der ersten Schleifen -->
          <span v-for="(middlePoint,key2) in bitArray.length-(key1+1)" :key="key2">
            <span v-for="(finalPoint,key3) in bitArray" :key="key3">
              <TextGeometry :text="this.getRandomBit()" :size="bitTextSize" :position="{x:startPoint+key2,y:key3,z:startPoint}"/>
              <TextGeometry :text="this.getRandomBit()" :size="bitTextSize" :position="{x:startPoint,y:key3,z:startPoint+key2}"/>
            </span>
          </span>
        </span>
      </Scene>
      
  
      <EffectComposer>
        <RenderPass />
        <UnrealBloomPass :strength="2" />
      </EffectComposer>
    </Renderer>
  </div>
</template>
  
<script>
  // import {render,h} from 'vue';
  import { MathUtils,Vector3 } from 'three';
  import TextGeometry from './customGeometries/TextGeometry.vue';
  
  import {
    Camera,
    EffectComposer,
    Renderer,
    RenderPass,
    SpotLight,
    Scene,
    UnrealBloomPass,
    PointLight,
  } from 'troisjs';
  
  export default {
    components: {
      Camera,
      EffectComposer,
      Renderer,
      RenderPass,
      SpotLight,
      Scene,
      UnrealBloomPass,
      TextGeometry,
      PointLight
    },
    data(){
      return {
        NUM_INSTANCES: 1000,
        cameraPosition: { y: 25, z: 15 },
        lightPosition: {x:0, y: 0, z: 20 },
        bitCount: 15,
        bitTextSize:0.3
      }
    },
    methods: {
      getRandomDimentions() {
        const { randFloat: rnd } = MathUtils;
        const x = rnd(-40,40);
        const y = rnd(-40,40);
        const z = rnd(-40,40);
        return {x,y,z}
      },
      getRandomBit() {
        const { randInt } = MathUtils;
        return String(randInt(0,1));
      },
      onPointerMove(e){
        this.lightPosition.x = e.clientX;
        this.lightPosition.y = e.clientY;
        this.lightPosition.z = this.$refs.renderer.camera.position.z*-1;
        console.log(this.$refs.renderer.camera)//todo: hier gets
        console.log(this.lightPosition)
        //console.log(e.clientX,e.clientY)
      }
    },
    computed: {
      bitArray() {
        let arrayStart = ((this.bitCount / 2) - 1) * -1;
        let bitArray = [];
        for(let bit=0; bit<this.bitCount;bit++){
          bitArray.push(arrayStart++);
        }
        return bitArray;
      },
      orbitCtrl() {
        return this.$refs.renderer.three.cameraCtrl;
      }

    },
    mounted(){
      this.orbitCtrl.target= new Vector3(0, 6, 0)
      this.orbitCtrl.autoRotate = true; 
      console.log(this.$refs.renderer.camera)
    }
  };
</script>
  
<style scoped>
.background-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: 0/* Your color or image here */;
  z-index: -1; /* Ensure it's behind other content */
}
</style>