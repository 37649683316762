<template>
    <div class="container">
        <h1>{{ title }}</h1>
        <p>{{ content }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            title:String,
            content:String
        }
    }
</script>

<style>
    @font-face {
        font-family: orbitron;
        src: url('../../public/assets/fonts/Orbitron-Bold.ttf');
    }
</style>
<style scoped>

    .container{
        color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        font-family: orbitron;
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 50%;
        transform: translateX(-50%);
    }

</style>