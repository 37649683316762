<template>
  <Text
    :text="bit"
    font-src= "/assets/fonts/helvetiker_regular.typeface.json"
    align="center"
    :size="this.size"
    :height="this.size/3"
    :position="this.position"
    :cast-shadow="true"
    @pointerEnter="onPointerEnter"
  >
    <PhongMaterial />
    <SpotLight color="white" :intensity="0.5" :position="{y: 0 }" :cast-shadow="false" :shadow-map-size="{ width: 1024, height: 1024 }" />
  </Text>
</template>

<script>
import { PhongMaterial, SpotLight, Text } from 'troisjs';
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 28,
    },
    color: {
      type: String,
      default: '#000000',
    },
    position: {
      type: JSON,
      default: {x:0,y:0,z:0}
    }
  },
  components:{
    Text,
    PhongMaterial,
    SpotLight
  },
  data() {
    return{
      bit: this.text
    }
  },
  methods:{
    onPointerEnter() {
      if(this.bit === '1'){
        this.bit = '0';
      }else{
        this.bit = '1';
      }
    }
  },
  mounted(){
    // setInterval(()=>{this.onPointerEnter()},200)
  }

};
</script>